/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'
import "./layout.css"
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const LayoutHome = ({ children }) => {
  const theme = createMuiTheme({
    typography: {
      fontFamily: 'Open Sans, sans-serif',
    },
 /*    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [raleway],
        },
      },
    }, */
  });

  return (
    <ThemeProvider theme={theme}>
      <Header siteTitle={"Inicio"} />
      <div
        style={{
          //margin: `0 15%`,
          //maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          '@media (max-width: 477px)': {
            padding: `0`,
          },
        }}
      >
        <main>{children}</main>
         {/*  © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a> */}
      </div>
      <Footer/>
    </ThemeProvider>
  )
}

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutHome
