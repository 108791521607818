import React from "react"
import { Container, Col, Row, Table, Image,Figure } from "react-bootstrap"
import { graphql } from "gatsby"
//import { Link } from "gatsby"
import {Typography,Paper,Grid} from '@material-ui/core'

import Layout from "../../components/layoutHome"

import SEO from "../../components/seo"
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  Title:{
          width:"100%",
          display:"flex",
          justifyContent:"Center",
          //margin:"30px",
          height:"30px",
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          fontSize:"28px",
          alignItems:"center"
        },
        SubTitle:{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          marginBottom:"10px",
          textAlign:"justify",
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          
        },
        imgContainer:{
            padding:"5px",
            width:"100%",
            display:"flex",
            justifyContent:"center",
            //alignItems:"center",
            height:"100%",
            maxHeight:"100%",
            
  
        },
        imgText:{
            textAlign:"justify",
  
        },
        img:{
            //border:"black",
            //borderStyle:"groove"
        },
        GridContainer:{
            margin:"10px"
        },
        EventContainer:{
          margin:"10px",
          width:"100%"
        },
        TextEvent:{
          width:"100%",
          padding:"10px",
          textAlign:"justify"
        },
        Seccion:{
            marginTop:"10px",
            marginBottom:"10px",
            paddingLeft:"10px",
            paddingRight:"10px"
        },
        espacio:{
            padding:"5px"
        },
        full:{
            width:"100%"
        },
        Principal:{
          maxHeight:"550px",
          maxWidth:"100%",
          padding:"10px",
        },
        titlePage:{
          marginBottom:"10px"
        },
        full:{
          maxWidth:"100%",
          width:"100%"
        },
        values:{
          padding:"5px",
            //width:"100%",
            display:"flex",
            justifyContent:"center",
            //alignItems:"center",
            //height:"100%",
            maxHeight:"100%",
        },
        MarginTables:{
          marginBottom:"10px",
          marginTop:"10px"
        },
        Secction2:{
          width:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          marginBottom:"10px",
          textAlign:"justify",
          background: "rgba(71,  0,  0, 0.8)",
          color:"white",
          marginTop:20,
          marginBottom:20
        }
  
    }));
const TableElement = props => (
  <div
    style={{
      backgroundColor: "#ffaaaa",
      border: "2px solid black",
      marginTop: "8px",
      marginBottom: "8px",
      padding: "4px 4px 4px 4px",
    }}
  >
    <p>{props.text}</p>
  </div>
)

const Nosotros = ({ data }) => {
  const Model = data.ceformaPagesShow4.data.page.content
  const classes = useStyles(); 
  return (
    <Layout>
      <SEO title="Conocenos" />
      <Container fuid>
        <Row className={classes.titlePage}>
          <h1>{Model.pageTitle}</h1>
        </Row>
        <Row>

            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
              style={{
                padding:"5px"
              }}
              >
              <Image src={Model.PrincipalImg} fluid />
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}
              >
              <p>{Model.descriptiveText}</p>
              </Grid>
            </Grid>
        </Row>
        <Row>
          <Col>
            <p>
              El Centro de Formación Laboral A.C. abarca las siguientes
              actividades:
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container fluid className={classes.full}>
            <Grid container >
           {Model.activities.map(img => (
            <Grid iteam xs={12} sm={6} md={6} lg={6} xl={6}
            className={classes.espacio}
            >
              <Paper className={classes.imgContainer}>
              <Figure>
                <Figure.Image
                  width={"100%"}
                  height={231}
                  alt={img.img}
                  src={img.img}
                  //src={"https://picsum.photos/500/200"}
                  className={classes.img}
                  />
                <Figure.Caption className={classes.imgText}>
                  {img.Actividad}
                </Figure.Caption>
                </Figure>
                </Paper>
            </Grid>
          ))} 
             </Grid>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col> 
        <div className={classes.values}>
              <Paper>
              <Figure>
                <Figure.Image
                  width={"100%"}
                  height={231}
                  alt={Model.ValuesDescription}
                  src={Model.ValuesIMG}
                  //src={"https://picsum.photos/900/200"}
                  className={classes.img}
                  />
                <Figure.Caption className={classes.imgText}>
                  <Typography className={classes.SubTitle}>
                    {Model.ValuesDescription}
                  </Typography>  
                  <Grid container>
                     <Grid iteam iteam xs={12} sm={6} md={6} lg={6} xl={6} >
                     <ul>
                    <li>Profesionalismo</li>
                    <li>Solidaridad</li>
                    <li>Honestidad</li>
                    <li>Sencillez</li>
                  </ul>
                    </Grid>
                    <Grid iteam iteam xs={12} sm={6} md={6} lg={6} xl={6} >
                    <ul>
                  <li>Trabajo</li>
                    <li>Disciplina</li>
                    <li>Honradez</li>
                    <li>Respeto</li>
                  </ul>
                    </Grid> 

                  </Grid>
                    

                  
                </Figure.Caption>
                </Figure>
                </Paper>
                </div>
              </Col>
        </Row>

        <Row>
          <Col>
            <p>{Model.CertificationText}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Image src={Model.Certification1} fluid />
          </Col>
          <Col>
            <Image src={Model.Certification2} fluid />
          </Col>
        </Row>

       
        <Row className={classes.MarginTables}>
        <Typography variant="h5" className={classes.Secction2} >
          Nuestra Mesa DIrectiva
        </Typography>
          <Table>
            <thead>
              <tr>
                <th>Mesa Directiva</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Puesto</th>
              </tr>
            </thead>
            <tbody>
              {Model.directiveTable.map(element => {
                return (
                  <tr>
                    <td>{element.Nombre}</td>
                    <td>{element.Puesto}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Row>
        <Row className={classes.MarginTables}>
        <Typography variant="h5" className={classes.Secction2} >
          ¿A Quienes ha capacitado CEFORMA?
        </Typography>
          <Col
          style={{
            maxWidth:"100%",
            overflow:"auto"
          }}
          >
            <Table>
              <thead>
                <tr>
                  <th>Empresa</th>
                  <th>Curso</th>
                  <th>Capacitados</th>
                </tr>
              </thead>
              <tbody>
                {Model.companies.map(company => {
                  return (
                    <tr>
                      <td>{company.Empresa}</td>
                      <td>{company.Curso}</td>
                      <td>{company.Capacitados}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Nosotros

export const query = graphql`
  query WhoWeAreQuery {
    ceformaPagesShow4 {
      data {
        page {
          content {
            companies {
              Curso
              Empresa
              Capacitados
            }
            pageTitle
            activities {
              Actividad
              img
            }
            PrincipalImg
            Certification1
            Certification2
            directiveTable {
              Nombre
              Puesto
            }
            descriptiveText
            CertificationText
            ValuesDescription
              ValuesIMG
              Nexos {
                description
                imgPrincipal
                title
              }
          }
        }
      }
    }
  }
`
